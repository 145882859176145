import request from 'api/axios-instance';

export type TData = {
  game: 'vortex' | 'lectio' | 'images';
  difficulty: 'easy' | 'medium' | 'hard';
  score: number;
  time: number;
};

export const sendGameResults = (data: TData) => request.post('v2/game', data);

export const fetchGameResults = (game: TData['game']) =>
  request.get(`v2/game?game=${game}&perPage=30&page=1`).then((res) => {
    return res.data.data;
  });
