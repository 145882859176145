import React, { useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import LMSButton from 'components/LMSButton';
import { sendGameResults, TData } from 'modules/student/api/repository/games';
import { useNotification } from 'hooks/notification';

import Timer from '../Timer';

const LECTIO_QUESTIONS = [
  {
    id: 1,
    question: 'Aspirinum',
    answer: 'aspirinum',
  },
  {
    id: 2,
    question: 'Ethanolum',
    answer: 'ethanolum',
  },
  {
    id: 3,
    question: 'Loratadine',
    answer: 'loratadine',
  },
  {
    id: 4,
    question: 'Cetirizine',
    answer: 'cetirizine',
  },
  {
    id: 5,
    question: 'Paracetamol',
    answer: 'paracetamol',
  },
  {
    id: 6,
    question: 'Ave',
    answer: 'ave',
  },
  {
    id: 7,
    question: 'Product',
    answer: 'product',
  },
  {
    id: 8,
    question: 'Decide',
    answer: 'decide',
  },
  {
    id: 9,
    question: 'Purchase',
    answer: 'purchase',
  },
  {
    id: 10,
    question: 'Price',
    answer: 'price',
  },
];

interface Props {
  nextStep: () => void;
}

const LectioTest = ({ nextStep }: Props) => {
  const notify = useNotification();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(1);
  const [answer, setAnswer] = useState<string>('');
  const [countCorrectAnswers, setCountCorrectAnswers] = useState<number>(0);
  const START_TIME: Date = new Date();

  const sendGameStats = () => {
    const END_TIME: Date = new Date();
    const time = END_TIME.getTime() - START_TIME.getTime();
    const result: TData = {
      game: 'lectio',
      difficulty: 'medium',
      score: countCorrectAnswers,
      time,
    };

    sendGameResults(result)
      .then(() => {
        nextStep();
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      });
  };

  const nextQuestion = (nextQuestionIndex: number, isCorrect: boolean) => {
    isCorrect && setCountCorrectAnswers((prevState) => prevState + 1);
    if (LECTIO_QUESTIONS.length < nextQuestionIndex) {
      sendGameStats();
    } else {
      setAnswer('');
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  const handleFinishTimer = () => {
    notify.error('Время вышло! Вы переходите к следующему вопросу.');
    setTimeout(() => nextQuestion(currentQuestionIndex + 1, false), 2000);
  };

  return (
    <div className="flex flex-col w-[80%] max-w-[960px] h-max rounded-[20px] theme-student-card pt-10 px-14 pb-20 m-auto">
      {LECTIO_QUESTIONS.map((question, index) => {
        return currentQuestionIndex === index + 1 ? (
          <div key={index} className="flex flex-col space-y-8">
            <div className="w-full flex flex-row justify-between items-center">
              <p className="font-base opacity-50">Задание №{index + 1}</p>
              <Timer time={120} className="!text-[16px]" onClose={handleFinishTimer} />
            </div>
            <div className="flex flex-col max-w-[460px] w-full mx-auto">
              <p className="text-[24px] font-semibold text-center leading-8 mb-7">
                Внимательно посмотрите на товар и напишите правильное название:
              </p>
              <div className="flex items-center justify-center mb-4">
                <p className="text-[60px] font-bold">{question.question}</p>
              </div>
              <input
                className="max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white mb-7"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
              <LMSButton
                className="self-center"
                disabled={!answer}
                suffix={<ArrowRight />}
                onClick={() =>
                  nextQuestion(
                    index + 1 + 1,
                    answer.trim().toLowerCase() === question.answer.toLowerCase()
                  )
                }
              >
                Далее
              </LMSButton>
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default LectioTest;
