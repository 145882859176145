import Avatar from 'components/Avatar';

const CoachCourseStudentInfo = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-7 px-6 py-11 theme-background-default-table rounded-xl md:flex-row">
        <div className="min-w-[74px] w-[74] h-[74px]">
          <Avatar />
        </div>
        <div className="max-h-[74px] w-full flex flex-row justify-center md:gap-x-[70px] md:h-[74px] md:justify-normal">
          <div className="flex flex-col justify-between">
            <span className="hidden theme-text--default text-base font-medium opacity-40 md:block">
              Фио
            </span>
            <span className="theme-text--default text-base font-semibold">Нурдаулет Куандык</span>
          </div>
          <div className="hidden flex-col justify-between md:flex">
            <span className="theme-text--default text-base font-medium opacity-40">Логин</span>
            <span className="theme-text--default text-base font-normal">nurdaulet_kuanduk</span>
          </div>
          <div className="hidden flex-col justify-between md:flex">
            <span className="theme-text--default text-base font-medium opacity-40">Курс</span>
            <span className="text-[#26A7EF] text-base font-normal">BIG Engineer Project</span>
          </div>
          <div className="hidden flex-col justify-between md:flex">
            <span className="theme-text--default text-base font-medium opacity-40">Группа</span>
            <span className="theme-text--default text-base font-normal">SPR503</span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-y-4 mt-4 px-5 py-8 theme-background-default-table rounded-xl sm:flex sm:flex-row sm:justify-between md:hidden">
        <div className="flex flex-col justify-between">
          <span className="theme-text--default text-xs font-medium opacity-40">Логин</span>
          <span className="theme-text--default text-sm font-normal">nurdaulet_kuanduk</span>
        </div>
        <div className="flex flex-col justify-between">
          <span className="theme-text--default text-xs font-medium opacity-40">Курс</span>
          <span className="text-[#26A7EF] text-sm font-normal">BIG Engineer Project</span>
        </div>
        <div className="flex flex-col justify-between">
          <span className="theme-text--default text-xs font-medium opacity-40">Группа</span>
          <span className="theme-text--default text-sm font-normal">SPR503</span>
        </div>
      </div>
    </>
  );
};

export default CoachCourseStudentInfo;
