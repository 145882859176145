import { ICourse, ICourseStudent } from 'modules/coach/entities/coach-courses/Students';
import request from 'api/axios-instance';

import { ICourseDao, ICourseStudentDao } from '../../dao/courses/GetStudents.dao';

export const fetchStudents = (groupId: number, courseId: number) =>
  request
    .get(
      `/v2/coach/total-courses/students?by_this_coach=1&group_id=${groupId}&course_id=${courseId}`
    )
    .then((res) => {
      const students: ICourse = mapCourseDaoToEntity(res.data.data);

      return students;
    });

function mapCourseDaoToEntity(courseDao: ICourseDao): ICourse {
  return {
    id: courseDao.id,
    name: courseDao.name,
    image: courseDao.image,
    dateFrom: courseDao.date_from,
    dateTo: courseDao.date_to,
    groupId: courseDao.group_id,
    groupName: courseDao.group_name,
    students: courseDao.students.map(mapCourseStudentDaoToEntity),
  };
}

function mapCourseStudentDaoToEntity(courseStudentDao: ICourseStudentDao): ICourseStudent {
  return {
    id: courseStudentDao.id,
    fullName: courseStudentDao.full_name,
    avatar: courseStudentDao.avatar,
    email: courseStudentDao.email,
    wasOnline: courseStudentDao.was_online,
  };
}
