import React from 'react';
import LMSButton from 'components/LMSButton';

interface Props {
  nextStep: () => void;
}

const LectioWelcome = ({ nextStep }: Props) => {
  return (
    <div className="flex flex-col space-y-8 items-center justify-center w-[80%] max-w-[960px] h-[500px] rounded-[20px] theme-student-card m-auto">
      <div className="flex flex-col space-y-4 items-center">
        <p className="text-[32px] font-semibold text-center leading-10 max-w-[512px]">
          Добро пожаловать в игру &quot;Lectio&quot;
        </p>
        <p className="text-[22px] text-center leading-6 max-w-[425px]">
          В этой игре вам предстоит правильно указать написание товаров и произношение английских
          слов.
        </p>
        <p className="text-[18px] text-center opacity-50">Вам даётся 5 минут на 10 вопросов.</p>
      </div>
      <LMSButton onClick={nextStep}>Начать</LMSButton>
    </div>
  );
};

export default LectioWelcome;
