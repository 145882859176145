import React from 'react';

interface IProps {
  progress: number;
  maxProgress: number;
}

const ProgressBar = ({ progress, maxProgress }: IProps) => {
  const percentage = (progress / maxProgress) * 100;
  let progressBarColor;

  if (percentage >= 70) {
    progressBarColor = 'green';
  } else if (percentage >= 30 && percentage < 70) {
    progressBarColor = 'yellow';
  } else {
    progressBarColor = 'gray';
  }

  return (
    <div className="w-full flex flex-col">
      <span className="font-[14px] mb-2">
        {progress} из {maxProgress}
      </span>
      <div className="w-full h-[2px] bg-[#e0e0e0] rounded overflow-hidden">
        <div
          className="h-full transition-all"
          style={{ width: `${percentage}%`, backgroundColor: progressBarColor }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
