import React from 'react';
import LMSButton from 'components/LMSButton';

interface Props {
  nextStep: () => void;
}

const PathfinderWelcome = ({ nextStep }: Props) => {
  return (
    <div className="flex flex-col space-y-8 items-center justify-center w-[80%] max-w-[960px] h-[500px] rounded-[20px] theme-student-card m-auto">
      <div className="flex flex-col space-y-4 items-center">
        <p className="text-[32px] font-semibold text-center leading-10 max-w-[512px]">
          Добро пожаловать в игру &quot;Следопыт&quot;
        </p>
        <p className="text-[22px] text-center leading-6 max-w-[425px]">
          В этой игре мы будем показывать вам пару картинок, и ваша задача - найти все отличия между
          ними.
        </p>
        <p className="text-[18px] text-center opacity-50">
          Вам даётся 10 вопросов и 2 минуты на каждый из них.
        </p>
      </div>
      <LMSButton onClick={nextStep}>Начать</LMSButton>
    </div>
  );
};

export default PathfinderWelcome;
