import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import LMSButton from 'components/LMSButton';

import { fetchGameResults } from '../../api/repository/games';
import Header from '../../components/Header';
import MathTest from '../../components/MathTest';
import MultiplicationTable from '../../components/MultiplicationTable';
import Title from '../../components/Title';

interface ITaskLevel {
  id: number;
  title: string;
  description: string;
  difficulty: 'easy' | 'medium' | 'hard';
  score: number;
  bestScore: number | null;
  time: number;
}

interface IGame {
  date: string;
  difficulty: 'easy' | 'medium' | 'hard';
  game: string;
  id: number;
  score: number;
  time: number;
}

const MathVortex = () => {
  const [activeTable, setActiveTable] = useState(0);
  const [activeLvl, setActiveLvl] = useState<ITaskLevel>();
  const [gameResults, setGameResults] = useState<IGame[]>([]);

  const taskLevels = useMemo(() => {
    return [
      {
        id: 1,
        title: 'Легкий уровень',
        description: 'Сложение и вычитание чисел',
        difficulty: 'easy',
        score: 0,
        bestScore: null,
        time: 300,
      },
      {
        id: 1,
        title: 'Средний уровень',
        description: 'Умножение и деление чисел',
        difficulty: 'medium',
        score: 0,
        bestScore: null,
        time: 180,
      },
      {
        id: 1,
        title: 'Сложный уровень',
        description: 'Все виды математических операций',
        difficulty: 'hard',
        score: 0,
        bestScore: null,
        time: 90,
      },
    ] as ITaskLevel[];
  }, [gameResults]);

  const MultiplicationTables = () => {
    return Array.from({ length: 10 }, (_, i) => i + 1);
  };

  useEffect(() => {
    fetchGameResults('vortex').then((res) => {
      setGameResults(res);
    });
  }, []);

  const findBestScore = (difficulty: 'easy' | 'medium' | 'hard') => {
    return gameResults
      .filter((item) => item.difficulty === difficulty)
      .reduce((acc, item) => {
        return acc > item.score ? acc : item.score;
      }, 0);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [activeLvl]);

  return (
    <>
      <Header />
      <div className="w-full  px-4 pt-12 md:pt-20 space-y-10">
        <Title className="text-center">Математический вихрь</Title>

        {!activeLvl?.id && (
          <section className="flex flex-col gap-y-8 items-center pt-10">
            <h2 className="text-2xl md:text-[32px]">Выберите уровень сложности</h2>
            <div className="grid grid-cols-3 gap-4 items-end">
              {taskLevels.map((item) => {
                return (
                  <div key={item.id} className="flex flex-col gap-2">
                    {findBestScore(item.difficulty) > 0 && (
                      <span>Ваш лучший результат - {findBestScore(item.difficulty)}%</span>
                    )}
                    <LMSButton onClick={() => setActiveLvl(item)} className="bg-white text-black">
                      {item.title}
                    </LMSButton>
                    <span>Описание: {item.description}</span>
                    <span>Время выполнения: {item.time} cекунд</span>
                  </div>
                );
              })}
            </div>
          </section>
        )}

        {!activeLvl?.id && (
          <section className="w-full flex flex-col items-center gap-y-10 pt-20">
            <h2 className="text-2xl md:text-[32px]">Тренировка</h2>
            <div className="flex justify-between gap-x-10 w-full">
              <div className="flex flex-rows flex-wrap items-center justify-center gap-4 flex-wrap max-w-[400px] w-full">
                {MultiplicationTables().map((item) => {
                  return (
                    <LMSButton
                      key={item}
                      onClick={() => setActiveTable(item)}
                      className="bg-white text-black max-w-[280px]"
                    >
                      Таблица умножения на {item}
                    </LMSButton>
                  );
                })}
              </div>

              <MultiplicationTable number={activeTable} onClose={() => setActiveTable(0)} />
            </div>
          </section>
        )}

        {activeLvl?.id && (
          <MathTest
            type={activeLvl.difficulty}
            time={activeLvl.time}
            onClose={() => setActiveLvl(undefined)}
          />
        )}
      </div>
    </>
  );
};

export default MathVortex;
