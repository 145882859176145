import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Finger } from 'assets/icons/finger.svg';
import LMSButton from 'components/LMSButton';
import { sendGameResults, TData } from 'modules/student/api/repository/games';
import { useNotification } from 'hooks/notification';

import Timer from '../Timer';

const QUESTIONS = [
  {
    id: 1,
    image: require('assets/images/pathfinder/pathfinder1.png'),
    coords: [
      { x: 665, y: 30 },
      { x: 597, y: 246 },
      { x: 723, y: 325 },
      { x: 648, y: 140 },
      { x: 631, y: 91 },
    ],
  },
  {
    id: 2,
    image: require('assets/images/pathfinder/pathfinder2.png'),
    coords: [
      { x: 654, y: 28 },
      { x: 423, y: 207 },
      { x: 523, y: 226 },
      { x: 541, y: 327 },
      { x: 646, y: 384 },
    ],
  },
  {
    id: 3,
    image: require('assets/images/pathfinder/pathfinder3.png'),
    coords: [
      { x: 466, y: 101 },
      { x: 711, y: 157 },
      { x: 574, y: 148 },
      { x: 440, y: 325 },
      { x: 684, y: 363 },
    ],
  },
  {
    id: 4,
    image: require('assets/images/pathfinder/pathfinder4.png'),
    coords: [
      { x: 490, y: 238 },
      { x: 486, y: 382 },
      { x: 619, y: 316 },
      { x: 759, y: 368 },
      { x: 790, y: 55 },
      { x: 790, y: 124 },
      { x: 790, y: 184 },
      { x: 790, y: 258 },
      { x: 790, y: 313 },
    ],
  },
  {
    id: 5,
    image: require('assets/images/pathfinder/pathfinder5.png'),
    coords: [
      { x: 731, y: 56 },
      { x: 609, y: 171 },
      { x: 778, y: 211 },
      { x: 619, y: 316 },
      { x: 548, y: 315 },
    ],
  },
  {
    id: 6,
    image: require('assets/images/pathfinder/pathfinder6.png'),
    coords: [
      { x: 450, y: 58 },
      { x: 575, y: 25 },
      { x: 471, y: 185 },
      { x: 717, y: 361 },
      { x: 761, y: 147 },
      { x: 598, y: 236 },
    ],
  },
  {
    id: 7,
    image: require('assets/images/pathfinder/pathfinder7.png'),
    coords: [
      { x: 603, y: 46 },
      { x: 639, y: 198 },
      { x: 657, y: 310 },
      { x: 683, y: 369 },
      { x: 579, y: 265 },
    ],
  },
  {
    id: 8,
    image: require('assets/images/pathfinder/pathfinder8.png'),
    coords: [
      { x: 570, y: 119 },
      { x: 739, y: 178 },
      { x: 621, y: 277 },
      { x: 715, y: 361 },
      { x: 531, y: 313 },
    ],
  },
  {
    id: 9,
    image: require('assets/images/pathfinder/pathfinder9.png'),
    coords: [
      { x: 460, y: 55 },
      { x: 465, y: 153 },
      { x: 645, y: 155 },
      { x: 542, y: 248 },
      { x: 759, y: 341 },
    ],
  },
  {
    id: 10,
    image: require('assets/images/pathfinder/pathfinder10.png'),
    coords: [
      { x: 458, y: 186 },
      { x: 565, y: 43 },
      { x: 641, y: 121 },
      { x: 708, y: 123 },
      { x: 782, y: 276 },
    ],
  },
];

interface Props {
  nextStep: () => void;
}

const PathfinderGame = ({ nextStep }: Props) => {
  const notify = useNotification();

  const [disabledImage, setDisabledImage] = useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(1);
  const [foundDifferences, setFoundDifferences] = useState<Record<string, number>[]>([]);
  const [wrongClicks, setWrongClicks] = useState<Record<string, number>[]>([]);
  const [countCorrectAnswers, setCountCorrectAnswers] = useState<number>(0);
  const [clickCount, setClickCount] = useState<number>(0);
  const MAX_CLICKS = 10;
  const START_TIME: Date = new Date();

  const sendGameStats = () => {
    const END_TIME: Date = new Date();
    const time = END_TIME.getTime() - START_TIME.getTime();
    const result: TData = {
      game: 'images',
      difficulty: 'medium',
      score: countCorrectAnswers + foundDifferences.length,
      time,
    };

    sendGameResults(result)
      .then(() => {
        nextStep();
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      });
  };

  const handleNextQuestion = (nextQuestionIndex: number) => {
    if (QUESTIONS.length < nextQuestionIndex) {
      sendGameStats();
    } else {
      setCountCorrectAnswers((prevState) => prevState + foundDifferences.length);
      setDisabledImage(false);
      setFoundDifferences([]);
      setWrongClicks([]);
      setClickCount(0);
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  const handleClickImage = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
    differences: { [key: string]: number }[]
  ) => {
    if (disabledImage) return;

    if (clickCount >= MAX_CLICKS) {
      notify.error(
        'Вы достигли максимального количества кликов! Вы будете перенесены на следующий уровень.'
      );
      setTimeout(() => handleNextQuestion(currentQuestionIndex + 1), 2000);
      return;
    }

    const imgElement = e.target as HTMLImageElement;
    const rect = imgElement.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    let isCorrect = false;

    differences.forEach((diff) => {
      if (x >= diff.x - 30 && x <= diff.x + 30 && y >= diff.y - 30 && y <= diff.y + 30) {
        isCorrect = true;
        if (!foundDifferences.some((fd) => fd.x === diff.x && fd.y === diff.y)) {
          setFoundDifferences([...foundDifferences, diff]);
        }
      }
    });

    if (!isCorrect) {
      setWrongClicks([...wrongClicks, { x, y }]);
      setClickCount(clickCount + 1);
    }
  };

  const handleFinishTimer = () => {
    notify.error('Время вышло! Вы переходите на следующий уровень.');
    setTimeout(() => handleNextQuestion(currentQuestionIndex + 1), 2000);
  };

  useEffect(() => {
    if (foundDifferences.length > 4) {
      setDisabledImage(true);
      notify.success('Вы прошли уровень! Чтобы перейти на следующий уровень, нажмите "Далее".');
    }
  }, [foundDifferences]);

  return (
    <div className="flex flex-col w-[80%] max-w-[960px] h-max rounded-[20px] theme-student-card pt-10 px-14 pb-20 m-auto">
      {QUESTIONS.map((question, index) => {
        return currentQuestionIndex === index + 1 ? (
          <div key={index} className="flex flex-col space-y-8">
            <div className="w-full flex flex-row justify-between items-center">
              <p className="font-base opacity-50">Задание №{index + 1}</p>
              <Timer time={120} className="!text-[16px]" onClose={handleFinishTimer} />
            </div>
            <div className="flex flex-col items-center max-w-[800px] w-full mx-auto">
              <p className="max-w-[450px] text-[24px] font-semibold text-center leading-8 mb-4">
                Внимательно сравните обе картинки и найдите все отличия между ними
              </p>
              <p className="text-[18px] text-center mb-7">
                Картинка слева правильная, а на картинке справа есть 5 отличий.
              </p>
              <div className="mb-4 relative">
                <img
                  className="w-full h-auto object-cover object-center"
                  alt={`pathfinder-${question.id}`}
                  src={question.image}
                  onClick={(e) => handleClickImage(e, question.coords)}
                />
                {foundDifferences.map((diff, index) => (
                  <div
                    key={index}
                    className="bg-transparent absolute w-[60px] h-[60px] border-4 border-green rounded-full"
                    style={{ top: diff.y - 30, left: diff.x - 30 }}
                  />
                ))}
                {wrongClicks.map((diff, index) => (
                  <div
                    key={index}
                    className="bg-transparent absolute w-[60px] h-[60px] border-4 border-red-500 rounded-full"
                    style={{ top: diff.y - 30, left: diff.x - 30 }}
                  />
                ))}
              </div>
              <div className="flex flex-row items-center justify-center opacity-50 space-x-2 mb-10">
                <Finger />
                <p className="text-[18px]">Нажмите на точку на картинке, где вы нашли отличие.</p>
              </div>
              <LMSButton
                className="self-center"
                suffix={<ArrowRight />}
                disabled={foundDifferences.length < 5}
                onClick={() => handleNextQuestion(index + 1 + 1)}
              >
                Далее
              </LMSButton>
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default PathfinderGame;
