import React, { useState } from 'react';

import LectioResult from '../../components/LectioResult';
import LectioTest from '../../components/LectioTest';
import LectioWelcome from '../../components/LectioWelcome';

const Lectio = () => {
  const [step, setStep] = useState<number>(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  return (
    <div className="w-full h-full p-10 m-auto">
      {step === 1 ? (
        <LectioWelcome nextStep={nextStep} />
      ) : step === 2 ? (
        <LectioTest nextStep={nextStep} />
      ) : (
        <LectioResult />
      )}
    </div>
  );
};

export default Lectio;
