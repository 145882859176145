import React, { useState } from 'react';

import PathfinderGame from '../../components/PathfinderGame';
import PathfinderResult from '../../components/PathfinderResult';
import PathfinderWelcome from '../../components/PathfinderWelcome';

const Pathfinder = () => {
  const [step, setStep] = useState<number>(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  return (
    <div className="w-full h-full p-10 m-auto">
      {step === 1 ? (
        <PathfinderWelcome nextStep={nextStep} />
      ) : step === 2 ? (
        <PathfinderGame nextStep={nextStep} />
      ) : (
        <PathfinderResult />
      )}
    </div>
  );
};

export default Pathfinder;
